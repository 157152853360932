import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

// import PricingPlansSplitted from "../components/pricingPlansSplitted";

const seoData = {
  title: "ONAYLARIM",
  description: "Soru ve cevaplar.",
  slug: "",
};

const ExtendedPricingPlans = () => {
  return (
    <Layout pageTitle="Soru ve Cevaplar">
      <Seo
        title={seoData.title}
        description={seoData.description || seoData.excerpt || "nothin’"}
        pathname={seoData.slug}
        article
      />
      {/* <PricingPlansSplitted></PricingPlansSplitted> */}
    </Layout>
  );
};

export default ExtendedPricingPlans;
